import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { explicitNull, or } from 'airbnb-prop-types';
import Head from 'next/head';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { selectDefaultMetaInfoData } from '@selectors/defaultMetaInfo';

import { getOpenGraphImage } from '@utils/image';
import { getPathUrl } from '@utils/urls';

import { useUserAgent } from '@common/contexts/userAgentContext';
import Footer from '@common/containers/Footer';
import { ContentWrapper, PageWrapper } from '@common/containers/Layout/Layout.styled';
import Navbar from '@common/components/Navbar';
import LayoutOverlay from '@common/containers/LayoutOverlay';

function Layout({
  children,
  description,
  ogImage,
  ogImageWidth,
  ogImageHeight,
  pageType,
  title,
  withTitleSuffix,
  canonicalPageUrl,
  footerNoSpacingTop,
  noIndex,
  minHeight,
  noFooter,
  noNavbar,
  ignoreOverflow,
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { isMobileApp } = useUserAgent();

  const { metaDescription: defaultDescription, metaImage: defaultImage } = useSelector(
    selectDefaultMetaInfoData
  );

  const metaTitle = withTitleSuffix ?
    t('general.metaTitle', '{{title}} | Chicorée', { title }) :
    title;

  const { asPath } = useRouter();
  const ogUrl = getPathUrl(`/${language}${asPath}`);

  return (
    <Fragment>
      <LayoutOverlay />
      {!isMobileApp && (
        <React.Fragment>
          <Head>
            <title>{metaTitle}</title>
            <meta
              content={description || defaultDescription}
              name="description"
            />
            {canonicalPageUrl !== null && (
              <link
                rel="canonical"
                href={getPathUrl(canonicalPageUrl)}
              />
            )}
            <meta
              content={getOpenGraphImage(ogImage || defaultImage?.url, pageType)}
              property="og:image"
            />
            <meta
              content={ogImageWidth || 1200}
              property="og:image:width"
            />
            <meta
              content={ogImageHeight || 630}
              property="og:image:height"
            />
            <meta
              content={ogUrl}
              property="og:url"
            />
            {noIndex && (
            <meta
              name="robots"
              content="noindex,nofollow"
            />
            )}
          </Head>

          {!noNavbar && <Navbar />}
        </React.Fragment>
      )}

      <PageWrapper
        minHeight={minHeight}
        ignoreOverflow={ignoreOverflow}
      >
        <ContentWrapper>{children}</ContentWrapper>

        {!noFooter && !isMobileApp && <Footer noSpacingTop={footerNoSpacingTop} />}
      </PageWrapper>
    </Fragment>
  );
}

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  canonicalPageUrl: or([PropTypes.string, explicitNull()]).isRequired,
  ogImage: PropTypes.string,
  ogImageWidth: PropTypes.number,
  ogImageHeight: PropTypes.number,
  pageType: PropTypes.string,
  withTitleSuffix: PropTypes.bool,
  children: PropTypes.node.isRequired,
  footerNoSpacingTop: PropTypes.bool,
  noIndex: PropTypes.bool,
  minHeight: PropTypes.string,
  noFooter: PropTypes.bool,
  noNavbar: PropTypes.bool,
  ignoreOverflow: PropTypes.bool,
};

Layout.defaultProps = {
  footerNoSpacingTop: false,
  description: null,
  ogImage: null,
  ogImageWidth: null,
  ogImageHeight: null,
  pageType: null,
  withTitleSuffix: true,
  noIndex: false,
  minHeight: null,
  noFooter: false,
  noNavbar: false,
  ignoreOverflow: false,
};

export default Layout;
